.landing-page-content {
  width: 100%;
  height: 100%;
  padding-top: 56px;
  overflow: auto;
}

.section-1 {
  background-image: url("/public/images/landing-page/bg-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: fit-content;
  padding: 0px 0px 200px 0px;
  position: relative;
}

.section-1-overlay {
  background-color: #0000009e;
  opacity: 1;
  height: 100%;
  width: 100%;
  position: absolute;
}

.left-tagline-div {
  padding: 150px 50px 0px 0px;
  display: flex;
  align-content: center;
  align-items: center;
  animation-name: fadeInLeft;
  animation-duration: 1.4s;
  animation-timing-function: cubic-bezier(0.21, 1.11, 0.81, 0.99);
}

.left-tagline-div .heading-title {
  color: #fff;
  font-weight: 700;
  font-size: 2.5em;
}

.left-tagline-div .section-1-desc {
  max-width: 80%;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
}

.right-tagline-div {
  display: flex;
  height: 100%;
  width: 100%;
  animation-name: fadeInRight;
  animation-duration: 1.4s;
  animation-timing-function: cubic-bezier(0.21, 1.11, 0.81, 0.99);
}

.right-tagline-div-inner {
  align-content: center;
  align-items: center;
  justify-content: center;
  background-image: url("/public/images/landing-page/bg-2.jpg");
  margin: 0px 0px 0px 50px;
  padding: 100px 20px 0px 0px;
  border-radius: 0% 0% 0% 50%;
  width: 100%;
}

.right-tagline-text-div {
  text-align: center;
  height: fit-content;
  margin: -30% 0% 0% 0%;
}

@media (max-width: 1024px) {
  .right-tagline-text-div {
    margin: 0% 0% 0% 38%;
  }

  .right-tagline-div-inner {
    padding: 50px 10px 0px 0px;
    margin: 0px 0px 0px 20px;
  }
}

.right-tagline-text-div h2 {
  font-size: 2.5rem;
  font-weight: 600;
  color: #272727;
}

.section-2 {
  position: relative;
  margin-top: -105px;
  margin-bottom: 0px;
  padding: 50px 0px 50px 0px;
  z-index: 2;
}

@media (max-width: 1024px) {
  .section-2 {
    margin-top: -65px;
    margin-bottom: 0px;
    padding: 0px 20px 0px 20px;
  }
}

.section-2-elem {
  background-color: #f3faff;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin: 0px 20px 0px 0px;
  padding: 20px 20px 20px 20px;
  font-size: 2em;
  width: 30%;
}

@media (max-width: 991px) {
  .section-2-elem {
    width: 100%;
  }
}

.section-2-elem h2 {
  font-size: 1.5rem;
  margin: 0;
}

.section-4-img {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 1005;
}

.section-4-text-div {
  display: flex;
  align-items: center;
  padding: 80px 80px 80px 80px;
  background-color: #f3faff;
  height: 100%;
}

.section-4-text-div-inner {
  animation-name: fadeInRight;
  animation-duration: 1.4s;
  animation-timing-function: cubic-bezier(0.21, 1.11, 0.81, 0.99);
}

.section-4-text-div-inner .top-text-div {
  margin: 0% 30% 0% 0%;
}

.skill-bar {
  background-color: #acacac;
  height: 10px;
  border-radius: 5px;
}

.skill-track {
  background-color: #1c4884;
  border-radius: 5px;
  height: 100%;
}

.section-5 {
    background-image: url(/public/images/landing-page/bg-4.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    
    height: fit-content;
}

.section-5-overlay {
    background-color: #1C4B84;
    opacity: 0.97;
    position: absolute;
    height: 100%;
    width: 100%;
}

.section-5-link {
    color: #ACACAC;
    font-size: 1rem;
    font-weight: 400;
}