.modal-background {
  align-items: center;
  background-color: hsla(0, 0%, 78%, 0.7);
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1031;
}

.msg-modal-container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 25px;
  width: 35%;
  max-height: 50%;
}

.msg-modal-container-lg {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 80%;
  padding: 25px;
  width: 65%;
}

.confirm-modal-container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 25px;
  width: 35%;
}

.entry-modal-container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 25px;
  width: 65%;
}

.modal-nav-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.modal-nav-div button {
  border: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  color: #fff;
  background-color: #565656;
  border-radius: 50%;
  transition: all 0.4s;
  /* box-shadow: 0 3px 5px rgba(0, 0, 0, .35);
    -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, .35);
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, .35);
    -o-box-shadow: 0 3px 5px rgba(0, 0, 0, .35);
    -ms-box-shadow: 0 3px 5px rgba(0, 0, 0, .35); */
  padding: 0;
}

.modal-nav-div button:disabled {
  background-color: #afafaf;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  -o-box-shadow: none;
  -ms-box-shadow: none;
}

.modal-nav-div button:hover {
  background-color: #313131;
  /* box-shadow: 0 4px 7px 0 rgba(0, 0, 0, .4);
    -moz-box-shadow: 0 4px 7px 0 rgba(0, 0, 0, .4);
    -webkit-box-shadow: 0 4px 7px 0 rgba(0, 0, 0, .4); */
  cursor: pointer;
}

.modal-nav-div button:hover * {
  cursor: pointer;
}

.modal-nav-div button:disabled:hover {
  background-color: #afafaf;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  -o-box-shadow: none;
  -ms-box-shadow: none;
  cursor: inherit;
}

.modal-nav-div button:disabled:hover * {
  cursor: inherit;
}

.modal-nav-div button span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.msg-modal-container .modal-title,
.msg-modal-container-lg .modal-title,
.confirm-modal-container .modal-title,
.entry-modal-container .modal-title {
  display: inline-block;
  text-align: center;
}

.msg-modal-container .modal-title h3,
.msg-modal-container-lg .modal-title h3,
.confirm-modal-container .modal-title h3,
.entry-modal-container .modal-title h3 {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 3px;
  line-height: 1;
  margin-bottom: 0;
  text-transform: capitalize;
}

.msg-modal-container .modal-body,
.msg-modal-container-lg .modal-body,
.confirm-modal-container .modal-body,
.entry-modal-container .modal-body {
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px;
  text-align: center;
}

.msg-modal-container .modal-body,
.msg-modal-container-lg .modal-body,
.confirm-modal-container .modal-body {
  display: block;
}

.entry-modal-container .modal-body .entry-row-div {
  width: 70%;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}

@media (max-width: 768px) {
  .entry-modal-container .modal-body .entry-row-div {
    width: 100%;
    padding: 0.2em;
  }

  .entry-modal-container .modal-body .entry-row-div .row .col {
    padding: 0;
  }
}

p:last-child {
  margin-bottom: 0;
}

.data-modal-background {
  align-items: center;
  background-color: hsla(0, 0%, 78%, 0.9);
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1032;
  top: 0;
  left: 0;
  /* animation-name: fadeIn;
    animation-duration: 1s; */
}

.data-modal-container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 90%;
  padding: 25px;
  width: 40%;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.21, 1.11, 0.81, 0.99);
}

.wider-data-modal-container {
  width: 70%;
}

@media only screen and (max-width: 767px) {
  .data-modal-container {
    height: 80%;
  }
}

.data-modal-container .modal-title {
  display: inline-block;
  text-align: center;
}

.data-modal-container .modal-title h3 {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 3px;
  line-height: 1;
  margin-bottom: 0;
  text-transform: capitalize;
}

.data-modal-container .modal-body {
  align-items: center;
  font-size: 14px;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px;
  text-align: center;
}

@media (max-width: 768px) {
  .data-modal-container .modal-title h3,
  .msg-modal-container .modal-title h3,
  .msg-modal-container-lg .modal-title h3,
  .confirm-modal-container .modal-title h3,
  .entry-modal-container .modal-title h3 {
    font-size: 18px;
  }

  .msg-modal-container .modal-body h4,
  .msg-modal-container-lg .modal-body h4 {
    font-size: 17px;
  }

  .msg-modal-container .modal-body,
  .msg-modal-container .modal-body p,
  .msg-modal-container-lg .modal-body p .confirm-modal-container .modal-body,
  .confirm-modal-container .modal-body p {
    font-size: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .msg-modal-container-lg,
  .data-modal-container,
  .entry-modal-container {
    width: 95%;
    padding: 5px;
  }

  .confirm-modal-container,
  .msg-modal-container {
    width: 52%;
    padding: 5px;
  }

  .data-modal-container .modal-body {
    padding: 3px;
  }
}

.bottom-5 {
  bottom: 5%;
}

.bottom-1 {
  bottom: 1%;
}

.bottom-3 {
  bottom: 3%;
}

.right-3 {
  right: 3%;
}

.right-1 {
  right: 1%;
}

.right-5 {
  right: 5%;
}

.input {
  font-size: 14px;
  line-height: 1.2;
  display: block;
  width: 100%;
  height: 40px;
  background: 0 0;
  padding: 0 1.5em;
}

.input-border {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  border-radius: 10px;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  /* -webkit-box-shadow: 0 5px 30px 0 rgba(0,0,0,.1); */
  -o-box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input-select {
  border-color: #fff;
}

.input-select:focus-visible {
  outline: none;
}

.input-select option:hover {
  background-color: #fff;
}

.input-range {
  -webkit-appearance: none;
  width: 100%;
  height: 6px;
  border-radius: 10px;

  background-repeat: no-repeat;
}

.input-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: #bd59d4;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  cursor: pointer;
}

.input-range::-webkit-slider-thumb:hover {
  background: #a04db3;
}

.input-range::-moz-range-thumb {
  -webkit-appearance: none;
  background: #bd59d4;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  cursor: pointer;
}

.input-range::-moz-range-thumb:hover {
  background: #a04db3;
}

.input-range::-ms-thumb {
  -webkit-appearance: none;
  background: #bd59d4;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  cursor: pointer;
}

.input-range::-ms-thumb:hover {
  background: #a04db3;
}

.textarea {
  outline: none;
  border: none;
  resize: none;
  line-height: 2;
}

.select-dropdown {
  overflow: auto;
  padding: 0 1.2em;
  transition: all 0.4s;
  position: absolute;
  background-color: #fff;
  width: 100%;
  z-index: 10;
  border-radius: 10px;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  /* -webkit-box-shadow: 0 5px 30px 0 rgba(0,0,0,.1); */
  -o-box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
}

.select-dropdown-li {
  cursor: pointer;
  border-radius: 8px;
  padding: 0 10px;
}

.select-dropdown-li:hover,
.select-dropdown-li-active {
  background: #e5e5e5;
}

.modal-form {
  padding: 0.5em;
  height: 100%;
  overflow: hidden;
}

.modal-list {
  max-height: 80%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0.5em 0 1.7em 0;
}

.item-list-name-logo {
  width: 50%;
}

@media (max-width: 768px) {
  .select-dropdown {
    padding: 5px;
  }

  .input {
    height: 30px;
  }

  .textarea {
    height: 40px;
  }

  .modal-form {
    padding: 0.2em;
  }
}

@media only screen and (min-width: 576px) and (max-width: 1900px) {
  .input {
    font-size: 12px;
    height: 45px;
  }
  .textarea {
    height: 65px;
  }
}

.widget-input {
  height: 30px;
}

.form-control {
  font-size: 14px;
  padding: 0 0.75rem;
}

.grey-label {
  color: #8f8f8f;
  font-weight: bold;
}

.top-grey-label {
  color: #8f8f8f;
  font-weight: bold;
  font-size: 0.8em;
}

.main-loading {
  height: 20px;
  width: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-timing-function: cubic-bezier(.21,1.11,.81,.99);
  animation-timing-function: cubic-bezier(.21,1.11,.81,.99);

}

.loading-sub {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-timing-function: cubic-bezier(.21,1.11,.81,.99);
    animation-timing-function: cubic-bezier(.21,1.11,.81,.99);

}

.responsive-label {
  font-size: 13px;
}

.responsive-label-s {
  font-size: 11px;
}

@media (max-width: 1400px) {
  .input,
  .responsive-label {
    font-size: 12px;
  }

  .responsive-label-s,
  .widget-label,
  .widget-label-s {
    font-size: 10px;
  }

  .widget-input {
    height: 25px;
    font-size: 10px;
  }

  .top-grey-label {
    font-size: 0.6em;
  }
}

/* override bootstrap navbar toggler */
.navbar-toggler {
  border: none;
}

.navbar-toggler:focus {
  box-shadow: none;
}
