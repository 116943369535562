.home-container {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 1;
  overflow-y: auto;
  background: #fff;
}

.profile-img-div {
  text-align: end;
}

.profile-img-div:hover img {
  transition: all 0.3s ease;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.profile-img-div-no-hover:hover img {
  transition: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  cursor: initial;
}

.profile-img-div img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.dropdown-wrapper {
  position: absolute;
  display: flex;
  overflow: hidden;
  border-radius: 10px;
  background: #fff;
  transition: all 0.3s ease;
  z-index: 999;
  right: 4em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: max-content;
  transform: translate(55px, 10px);
}

@media (max-width: 990px) {
  .dropdown-wrapper {
    right: 2em;
    transform: translate(25px, 10px);
  }
}

.dropdown-wrapper ul {
  padding: 0.5em;
  transition: all 0.3s ease;
  margin: 0;
}

.dropdown-wrapper ul li {
  line-height: 35px;
}

.dropdown-wrapper ul li a {
  color: #000;
  font-size: small;
  padding: 0 10px;
  border-radius: 10px;
  text-decoration: none;
  display: block;
}

.dropdown-wrapper ul .list-div:hover {
  background: #e5e5e5;
}

.dropdown-wrapper ul li:hover a * {
  cursor: pointer;
}

.dropdown-wrapper ul li a .icon {
  height: 30px;
  width: 30px;
  margin-right: 13px;
  background: #292933;
  display: flex;
  justify-content: center;
  border-radius: 50%;
}

.dropdown-wrapper ul li a .icon span {
  display: flex;
  align-items: center;
}

.home-container .home-row .col .container {
  height: 40px;
}

@media (max-width: 990px) {
  .home-container .home-row .col .container {
    height: 35px;
  }
}

.home-container .home-row .col .container h4 {
  margin-bottom: 0;
}

.section-home {
  padding: 1.5em 2em;
  margin: 0 auto;
}

@media (max-width: 990px) {
  .section-home {
    padding: 0.5em 0.5em;
  }
}

.total-section,
.list-section {
  background-color: #fff;
  border-radius: 10px;
  padding: 0.7em;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
}

.list-section {
  overflow: hidden;
}

.inner-list-section {
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  height: 100%;
}

.app-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 90px;
  height: 35px;
  background-color: #8BC344;
  border-radius: 25px;
  font-size: 12px;
  color: #fff;
  line-height: 1.2;
  text-transform: capitalize;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.35);
  -o-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.35);
  -ms-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.35);
}

.app-btn.active {
  background-color: #8BC344;
}

@media (max-width: 1400px) {
  .app-btn {
    min-width: 80px;
    font-size: 11px;
    height: 30px;
  }

  .total-section,
  .list-section {
    padding: 0.5em;
  }

  .home-container .home-row .col .container {
    height: 35px;
  }
}

.app-btn:hover {
  background-color: #476522;
  box-shadow: 0 4px 7px 0 rgba(95, 85, 85, 0.5);
  -moz-box-shadow: 0 4px 7px 0 rgba(95, 85, 85, 0.5);
  -webkit-box-shadow: 0 4px 7px 0 rgba(95, 85, 85, 0.5);
  -o-box-shadow: 0 4px 7px 0 rgba(95, 85, 85, 0.5);
  -ms-box-shadow: 0 4px 7px 0 rgba(95, 85, 85, 0.5);
}

.item-logo,
.category-logo {
  width: 42px;
}

.small-logo {
  width: 25px;
}

@media (max-width: 768px) {
  .app-btn {
    font-size: 10px;
    height: 30px;
    min-width: 75px;
  }

  .category-logo,
  .item-logo {
    width: 30px;
  }
}

.icon-grid {
  display: flex;
  flex-direction: column;
  height: calc(100% - 6em);
  overflow-y: auto;
}

.icon-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.icon {
  flex: 1;
  padding: 0 10px;
  box-sizing: border-box;
}

.icon-grid > .icon-row > .icon > .category-logo:hover {
  cursor: pointer;
}

@media (max-width: 996px) {
  .navbar-title {
    font-size: calc(1.1rem + 0.3vw);
  }
}

/* add items */
/* numpad */

.numpad-btn,
.big-label {
  font-size: 1.8em;
  font-weight: bold;
}

.mid-label {
  font-size: 1.2em;
  font-weight: bold;
}

@media (max-width: 1400px) {
  .big-label,
  .mid-label {
    font-size: 0.8em;
  }

  .top-grey-label {
    font-size: 0.6em;
  }

  .numpad-btn {
    font-size: 2em;
  }

  .currency-symbol {
    font-size: x-small;
  }
}

.list-div {
  background-color: #fbfbfb;
  margin: 0.5em 0;
  padding: 0.2em;
  border-radius: 10px;
  overflow: hidden;
}

.list-div:hover,
/* having a '*' allows all child elements in the div to have the property */
.list-div:hover * {
  cursor: pointer;
}

.list-div-active,
.list-div:hover {
  background-color: #f3f3f3;
}

.list-div-no-hover:hover {
  background-color: #fbfbfb;
  margin: 0.5em 0;
  padding: 0.2em;
  border-radius: 10px;
  overflow: hidden;
  cursor: initial;
}

.list-div-no-hover:hover * {
  cursor: initial;
}

.list-amount-label {
  font-weight: bold;
  font-size: small;
}

.btn-bottom-responsive {
  bottom: 1%;
  z-index: 650;
}

.highlighted-section {
  background-color: #fbfbfb;
  margin: 0.5em 0;
  padding: 0.2em;
  border-radius: 10px;
}

@media (max-width: 576px) {
  .btn-bottom-responsive {
    bottom: 7%;
  }
}

.modal-form-submit-div {
  height: 45px;
}

.modal-entry-div {
  max-height: 53px;
}

/* slick componentes customization */

.slick-prev:before,
.slick-next:before {
  color: #a747bd;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  color: #773287;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  color: #c1b9c5;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  color: #c1b9c5;
}

.slick-prev {
  left: -12px;
}

.slick-next {
  right: -12px;
}

.slider-cell {
  height: 60vh;
}

@media (max-width: 576px) {
  .slider-cell {
    height: 65vh;
  }
}

.entry-list-bg-div {
  background-color: #f8f8f8;
  margin: 0.5em 0;
  border-radius: 10px;
  padding: 0.2em;
}

.custom-graph-tooltip {
  border-radius: 10px;
  background-color: #f8f8f8;
  padding: 5px;
}

.custom-graph-tooltip-inner {
  border-radius: 10px;
  background-color: #f3f3f3;
  padding: 5px;
}

.pie-icons:hover {
  cursor: pointer;
}

@media (max-width: 350px) {
  .pie-icons {
    display: none;
  }
}

.list-loader-div {
  position: absolute;
  bottom: 20px;
  width: 100%;
}

.backdrop-blur-md {
  --tw-backdrop-blur: blur(12px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia);
}

.product-list {
  position: relative;
  padding: 1.5em 1rem;
  padding-top: calc(1.5em + 112px);
  height: 100%;
  overflow-y: auto;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-timing-function: cubic-bezier(0.21, 1.11, 0.81, 0.99);
  animation-timing-function: cubic-bezier(0.21, 1.11, 0.81, 0.99);
  background-color: #F4F4F4;
}

@media (max-width: 1400px) {
  .product-list {
    padding: 0.5em 0.5em;
    padding-top: calc(1.5em + 112px);
  }
}

.product-list-grid {
  width: 70%;
  margin: auto;
}

.tile-font {
  font-size: 13px;
}

.grid {
  display: grid;
}

.gap-8 {
  gap: 2rem;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

@media (min-width: 640px) {
  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (min-width: 1440px) {
  .lg\:grid-cols-3 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (min-width: 1600px) {
  .lg\:grid-cols-3 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

.bg-neutral-100\/80 {
  background-color: hsla(0, 0%, 96%, 0.8);
}

.bg-neutral-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.product-image {
  padding: 0.5em;
  display: flex;
  justify-content: center;
}

.font-semibold {
  font-weight: 600;
}

.font-medium {
  font-weight: 500;
}

.text-neutral-500 {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity));
}

.text-neutral-900 {
  --tw-text-opacity: 1;
  color: rgb(23 23 23 / var(--tw-text-opacity));
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.rsis-image {
  background-size: contain !important;
  background-repeat: round;
}

/* simple slider */
.rsis-image,
.rsis-container {
  border-radius: 10px;
}

.shopping-cart-btn {
  position: relative;
  font-size: 1.3em;
  margin-right: 1em;
}

.cart-counter {
  color: #fff;
  width: 1.2rem;
  height: 1.2rem;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(7%, 25%);
  background-color: #292933;
  font-size: 0.55em;
}

.cart-item-img {
  width: 80px;
  height: 80px;
  border-radius: 10px;
}

.product-search-container {
  position: fixed;
  top: 65px;
  left: 50%;
  transform: translateX(-50%);
  width: 30%;
  z-index: 1000;
}

.product-tile-wrapper {
  margin: auto;
}

.product-tile {
  border-radius: 10px;
  background-color: #fff;
  width: 220px;
  height: 280px;  
}

.product-tile .product-tile-text {
  overflow: auto;
  height: calc(130px - 1.2em);
}

@media (max-width: 1400px) {
  .product-search-container {
    width: 75%;
  }
}

.product-filter-container {
  position: fixed;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  z-index: 1000;
  padding: 8px;
}

.product-search-container .container {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 7px 0px;  
  background-color: #fbfbfb;
  border-radius: 10px;
}

.product-count {
  position: relative;
  text-align: center;
  margin-top: 0.3em;  
}

.product-count > label {
  width: fit-content;
  margin: auto;
  background-color: hsla(0, 0%, 96%, 0.8);
  padding: 0.2em 0.3rem;
  border-radius: 10px;
}

.product-filter-div {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 7px 0px;
  padding: 8px;
  background-color: #fbfbfb;
  border-radius: 10px;
  transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
  max-height: 200px;
}

.filter-toggle-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.3em;
}

.filter-options,
.order-variants {
  transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
  overflow: hidden;
  opacity: 1;
  max-height: 200px; /* Adjust based on content height */
  overflow-y: scroll;
}

.product-filter-div.hidden,
.filter-options.hidden,
.order-variants.hidden {
  opacity: 0;
  max-height: 0;
}

.order-variant-img {
  width: 60px;
  height: 60px;
  border-radius: 10px;
}
