.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.p-r-55 {
  padding-right: 55px;
}

.p-l-55 {
  padding-left: 55px;
}

.p-b-30 {
  padding-bottom: 30px;
}

.p-t-80 {
  padding-top: 80px;
}

.p-b-20 {
  padding-bottom: 20px;
}

.p-t-57 {
  padding-top: 57px;
}

.p-b-37 {
  padding-bottom: 37px;
}

.p-b-112 {
  padding-bottom: 112px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-b-25 {
  margin-bottom: 25px;
}

input,
select {
  outline: none;
  border: none;
}

button {
  outline: none !important;
  border: none;
  background: 0 0;
}

.flex-c {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
}

a {
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  text-decoration: none;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #dee2e6;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #343a40;
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(4rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes moveInTop {
  0% {
    opacity: 0;
    transform: translateY(-4rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.footer a {
  color: #fff;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #999bad !important;
}

ul {
  padding: 0;
}

li {
  list-style: none;
}

@media screen and (min-width: 0px) and (max-width: 1900px) {
  .p-b-112 {
    padding-bottom: 2em;
  }

  .p-t-80 {
    padding-top: 3em;
  }

  .p-b-30 {
    padding-bottom: 1em;
  }

  .p-t-57 {
    padding-top: 1.2em;
  }

  .p-b-20 {
    padding-bottom: 0.5em;
  }

  .p-b-37 {
    padding-bottom: 0.7em;
  }

  .m-b-10 {
    margin-bottom: 0.2em;
  }

  .m-b-20 {
    margin-bottom: 0.3em;
  }

  .m-b-25 {
    margin-bottom: 1.5em;
  }

  a {
    font-size: 12px;
  }

  li {
    font-size: 12px;
  }
}

.widget-label {
  font-size: 12px;
}

.widget-label-s {
  font-size: 11px;
}

@media (max-width: 576px) {
  a {
    font-size: 10px;
  }
}
