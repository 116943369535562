.auth-parent-row {
  width: 100%;
}

.auth-parent-row .auth-img-col .auth-img-div {
  max-width: 50%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.cookie-consent-container {
  background: linear-gradient(-135deg, rgb(41, 41, 51), rgb(45, 45, 57));
  height: 79px;
  padding: 8px;
  color: #fff;
  font-size: 12px;
  width: 100%;
  position: fixed;
  bottom: 19.5px;
  text-align: center;
  z-index: 100;
}

.cookie-consent-container .auth-form-btn {
  margin: 0.25em auto 0 auto;
}

@media (min-width: 1200px) {
  .auth-parent-row .auth-img-col .auth-img-div {
    max-width: 25%;
  }
}

.auth-parent-row .auth-img-col .auth-img-div .auth-img {
  max-width: 100%;
  border-radius: 10px;
  animation-name: moveInTop;
  animation-duration: 1.4s;
  animation-timing-function: cubic-bezier(0.21, 1.11, 0.81, 0.99);
}

.auth-img-title {
  animation-name: moveInLeft;
  animation-duration: 1.4s;
  animation-timing-function: cubic-bezier(0.21, 1.11, 0.81, 0.99);
}

.auth-img-sub-title {
  animation-name: moveInBottom;
  animation-duration: 1.4s;
  animation-timing-function: cubic-bezier(0.21, 1.11, 0.81, 0.99);
}

.auth-container {
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}

.auth-form-col .wrap-form {
  width: 390px;
  margin: auto;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  animation-name: fadeIn;
  animation-duration: 2.4s;
  animation-timing-function: cubic-bezier(0.21, 1.11, 0.81, 0.99);
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 480px) {
  .auth-form-col .wrap-form {
    width: 90%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.auth-form {
  width: 100%;
}

.register-form {
  height: 60vh;
}

.auth-form-title {
  display: block;
  font-size: 30px;
  color: #4b2354;
  line-height: 1.2;
  font-weight: 500;
  text-align: center;
}

.auth-form-input-container {
  height: 60%;
  overflow: scroll;
  padding: 0.5em 0.1em;
  margin-bottom: 10px;
}

.wrap-input {
  width: 100%;
  position: relative;
  background-color: #fff;
  border-radius: 10px;
}

.wrap-input-in {
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
}

.wrap-input-out {
  transform: translateX(150%);
  transition: transform 0.3s ease-in-out;
}

.container-auth-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.auth-form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 160px;
  height: 50px;
  background-color: #8BC344;
  border-radius: 25px;
  font-size: 14px;
  color: #fff;
  line-height: 1.2;
  text-transform: capitalize;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.35);
  -o-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.35);
  -ms-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.35);
}

.auth-form-btn:hover {
  background-color: #476522;
  box-shadow: 0 4px 7px 0 rgba(95, 85, 85, 0.5);
  -moz-box-shadow: 0 4px 7px 0 rgba(95, 85, 85, 0.5);
  -webkit-box-shadow: 0 4px 7px 0 rgba(95, 85, 85, 0.5);
  -o-box-shadow: 0 4px 7px 0 rgba(95, 85, 85, 0.5);
  -ms-box-shadow: 0 4px 7px 0 rgba(95, 85, 85, 0.5);
}

.auth-form-btn:disabled {
  background-color: #999;
  box-shadow: 0 10px 30px 0 rgba(153, 153, 153, 0.5);
  -moz-box-shadow: 0 10px 30px 0 rgba(153, 153, 153, 0.5);
  -webkit-box-shadow: 0 10px 30px 0 rgba(153, 153, 153, 0.5);
  -o-box-shadow: 0 10px 30px 0 rgba(153, 153, 153, 0.5);
  -ms-box-shadow: 0 10px 30px 0 rgba(153, 153, 153, 0.5);
}

.auth-form-btn:disabled:hover {
  box-shadow: 0 10px 30px 0 rgba(123, 123, 123, 0.5);
  -moz-box-shadow: 0 10px 30px 0 rgba(123, 123, 123, 0.5);
  -webkit-box-shadow: 0 10px 30px 0 rgba(123, 123, 123, 0.5);
  -o-box-shadow: 0 10px 30px 0 rgba(123, 123, 123, 0.5);
  -ms-box-shadow: 0 10px 30px 0 rgba(123, 123, 123, 0.5);
}

.login-social-item {
  font-size: 25px;
  color: #3b5998;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
  margin: 5px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
}

.login-social-item:hover {
  color: #3b5998;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
}

.txt1 {
  font-size: 16px;
  line-height: 1.4;
  color: #999;
}

.footer p {
  font-size: small;
  color: #fff;
}

.currency-select-dropdown {
  height: 60px;
  overflow: auto;
  padding: 10px 20px 10px 23px;
  transition: all 0.4s;
  position: absolute;
  background-color: #f3f3f3;
  z-index: 10;
  border-radius: 0 0 0 10px;
  width: 100%;
}

.currency-select-dropdown li:hover {
  cursor: pointer;
}

@media (max-width: 575px) {
  .auth-form-title {
    font-size: 18px;
  }

  .auth-form-btn {
    height: 30px;
    font-size: 10px;
    min-width: 75px;
  }

  .cookie-consent-container {
    height: 65px;
  }
  .cookie-consent-container label {
    font-size: 9px;
  }

  .txt1,
  .footer p {
    font-size: 10px;
  }

  .login-social-item {
    width: 35px;
    height: 35px;
    font-size: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 1900px) {
  .auth-form-title {
    font-size: 25px;
  }

  .auth-form-btn {
    font-size: 12px;
    height: 45px;
    min-width: 145px;
  }

  .txt1 {
    font-size: 12px;
  }

  .cookie-consent-container {
    height: 85px;
  }
  .cookie-consent-container .auth-form-btn {
    margin-top: 0;
  }
}

.footer {
  height: 19.5px;
  background: linear-gradient(-135deg, rgb(41, 41, 51), rgb(45, 45, 57));
}

.auth-inner {
  height: calc(100vh - 91.5px);
  padding-top: 72px;
  width: 100%;
}

